html{
    font-size: 16px;
}
body, h1, h1.title, h2, h3, h4, h5, h6, button{
    @extend .foco;
}
#site_wrap{
    
}
.container{
    max-width:1400px;
    margin-left:auto;
    margin-right:auto;
    padding-right: 60px;
    padding-left: 60px;
    @include devices(medium,large){
        padding-right: 30px;
        padding-left: 30px;
    }
    @include devices(small,medium){
        padding-right: 25px;
        padding-left: 25px;
    }
    @include devices_max(small){
        padding-right: 15px;
        padding-left: 15px;
    }
}
.container-fluid{
    width: 100%;
}
.row{
    margin-left: 0;
}
p,span,li,label,input,button{
    @extend .foco_light;
}
.back_white{
    background-color: #fff!important;
}
.animatable{
    opacity: 0;
}
.color_orange{
    color: $orange;
}