.setup_container{
	background-color: $blue;
	margin-top: 80px;
	padding-top: 100px;
	.setup_icon_col{
		position: relative;
		img{
			position: absolute;
			bottom: 0;
			right: 0;
			height: 350px;
		}
	}
	.text_container{
		max-width: 70%;
		min-height: 400px;
		*{
			color: white;
		}
		.title{
			margin-bottom: 40px;
			p{
				font-size: 2em;
            	line-height: 1.2em;
            }
		}
		.text{
			font-size: 14px;
		}
	}
	.row{
		
	}
	@include devices_max(small){
		padding-top: 20px;
		.setup_icon_col{
			display: none;
		}
		#setup_text_col{
			max-width: 100%;
			width: 100%;
			flex-basis: 100%;
		}
		.text_container{
			max-width: 100%;
			min-height: 0;
		}
	}
}

.setup_form_container{
	background-color: $background-white;
	@include devices_max(small){
		#form_spacer{
			display: none;
		}
		#form_col{
			max-width: 100%;
			width: 100%;
			flex-basis: 100%;
		}
	}
}
.assistant_form_container{
	background-color: #fff;
	padding: 40px 120px;
	@include custom_devices_max(1281px){
		padding: 40px 60px;
	}
	@include devices_max(small){
		padding: 20px 20px;
	}
	border-radius: 15px;
	margin-bottom: 50px;
	box-shadow: 0 10px 10px rgba(0,0,0,0.2);
	.form_title{
		font-size: 1.5em;
		text-align: center;
		margin-bottom: 100px;
		color: $text-color;
		@include custom_devices_max(1281px){
			margin-bottom: 30px;
		}
	}
	input{
    	border: 0px;
    	border-bottom: 2px solid $text-color;
    	font-weight:bold;
    	color: $text-color;
  	}
  	label{
  		color: #000;
    	font-weight: 700;
    	font-size: 1em;
    	color: $text-color;
  	}
  	textarea{
	  	padding: 0;
	    resize: none;
	    background: transparent url('/assets/under.png') repeat;
	    border:none;
	    height:150px;
	    width: 100%;
	    overflow:hidden;
	    line-height:38px;
	    color: $text-color;
  	}
  	.submit-btn-container{
  		text-align: right;
  	}
}