@charset "UTF-8";
@media only screen and (max-width: 769px) {
  .not-break .col-1 {
    max-width: 50%;
    width: 50%;
    flex-basis: 50%;
  }
}

@media only screen and (max-width: 769px) {
  .not-break .col-2 {
    max-width: 16.66667%;
    width: 16.66667%;
    flex-basis: 16.66667%;
  }
}

@media only screen and (max-width: 769px) {
  .not-break .col-3 {
    max-width: 25%;
    width: 25%;
    flex-basis: 25%;
  }
}

@media only screen and (max-width: 769px) {
  .not-break .col-4 {
    max-width: 33.33333%;
    width: 33.33333%;
    flex-basis: 33.33333%;
  }
}

@media only screen and (max-width: 769px) {
  .not-break .col-5 {
    max-width: 41.66667%;
    width: 41.66667%;
    flex-basis: 41.66667%;
  }
}

@media only screen and (max-width: 769px) {
  .not-break .col-6 {
    max-width: 50%;
    width: 50%;
    flex-basis: 50%;
  }
}

@media only screen and (max-width: 769px) {
  .not-break .col-7 {
    max-width: 58.33333%;
    width: 58.33333%;
    flex-basis: 58.33333%;
  }
}

@media only screen and (max-width: 769px) {
  .not-break .col-8 {
    max-width: 66.66667%;
    width: 66.66667%;
    flex-basis: 66.66667%;
  }
}

@media only screen and (max-width: 769px) {
  .not-break .col-9 {
    max-width: 75%;
    width: 75%;
    flex-basis: 75%;
  }
}

@media only screen and (max-width: 769px) {
  .not-break .col-10 {
    max-width: 83.33333%;
    width: 83.33333%;
    flex-basis: 83.33333%;
  }
}

@media only screen and (max-width: 769px) {
  .not-break .col-11 {
    max-width: 91.66667%;
    width: 91.66667%;
    flex-basis: 91.66667%;
  }
}

@media only screen and (max-width: 769px) {
  .not-break .col-12 {
    max-width: 100%;
    width: 100%;
    flex-basis: 100%;
  }
}

.foco_light, p, span, li, label, input, button, .secondary_banner .laptop_block_text_container .laptop_block_text span:last-child {
  font-style: normal;
  font-weight: 300 !important;
}

.foco, body, h1, h1.title, h2, h3, h4, h5, h6, button {
  font-family: "foco";
}

html {
  font-size: 16px;
}

.container {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 60px;
  padding-left: 60px;
}

@media only screen and (min-width: 769px) and (max-width: 992px) {
  .container {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 769px) {
  .container {
    padding-right: 25px;
    padding-left: 25px;
  }
}

@media only screen and (max-width: 480px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.container-fluid {
  width: 100%;
}

.row {
  margin-left: 0;
}

.back_white {
  background-color: #fff !important;
}

.animatable {
  opacity: 0;
}

.color_orange {
  color: #f04c23;
}

/*------------------------------------*
  #BUTTONS
\*------------------------------------*/
.login_button {
  color: white;
  background-color: #f04c23;
  border-radius: 20px;
  min-height: 36px;
  padding: 9px 24px;
  transition: all 0.5s ease;
}

.login_button:hover {
  color: white;
  border-color: #d2421f;
}

.watch_video_button {
  color: white;
  background: transparent;
  border-radius: 20px;
  min-height: 36px;
  border: 1px solid white;
  margin-left: 15px;
  padding: 9px 24px;
  transition: all 0.5s ease;
}

@media only screen and (max-width: 371px) {
  .watch_video_button {
    margin: 15px 0 15px 0;
  }
}

.watch_video_button:hover {
  color: white;
}

header {
  padding: 20px 0;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  background-color: #fafafa;
}

@media only screen and (max-width: 992px) {
  header {
    padding: 7px 0;
  }
}

@media only screen and (min-width: 769px) {
  header .logo_col {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-align-items: center;
    align-items: center;
  }
}

@media only screen and (max-width: 769px) {
  header .logo_col img {
    margin-top: 6px;
  }
}

header .logo_col .logo_container {
  position: relative;
}

header .logo_col .logo_container span {
  position: absolute;
  bottom: -12px;
  right: 0;
  font-size: 0.6875em;
}

@media only screen and (max-width: 992px) {
  header .logo_col .logo_container span {
    display: none;
  }
}

header .logo_col .logo_container img {
  height: 28px;
}

@media only screen and (max-width: 769px) {
  header .logo_col .logo_container img {
    margin-top: 17px;
  }
}

@media only screen and (max-width: 992px) {
  header .logo_col {
    max-width: 83.33333% !important;
    width: 83.33333% !important;
    flex-basis: 83.33333% !important;
  }
}

header .menu_col {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}

header .menu_col #main_menu_ul {
  margin: 0;
}

header .menu_col #main_menu_ul li {
  list-style-type: none;
  display: inline-block;
}

header .menu_col #main_menu_ul li a {
  text-decoration: none;
  color: #465058;
  padding: 0 10px;
  font-size: 0.875em;
  font-weight: 400;
  transition: color 0.3s ease;
}

header .menu_col #main_menu_ul li a:hover {
  color: #f04c23;
}

header .menu_col #main_menu_ul li.active a {
  color: #f04c23;
}

@media only screen and (max-width: 992px) {
  header .menu_col #main_menu_ul {
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  header .menu_col .login_button {
    display: none;
  }
}

@media only screen and (min-width: 992px) {
  header .menu_col #burger_ic {
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  header .menu_col {
    max-width: 16.66667% !important;
    width: 16.66667% !important;
    flex-basis: 16.66667% !important;
  }
}

header .menu_col .flag_icon {
  margin-left: 10px;
}

@media only screen and (max-width: 992px) {
  header .menu_col .flag_icon {
    display: none;
  }
}

.burger_menu_container {
  max-height: 0;
  transition: max-height 1s ease;
  overflow: hidden;
}

.burger_menu_container #burger_menu_ul {
  margin: 0;
}

.burger_menu_container #burger_menu_ul li a {
  display: block;
  text-align: center;
  padding: 10px 0;
  color: #707579;
  text-decoration: none;
  font-weight: 400;
}

.burger_menu_container #burger_menu_ul li.active a {
  color: #f04c23;
}

.burger_menu_container .flag_burger {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

footer {
  background: transparent;
  position: relative;
  padding: 100px 0 30px 0;
}

@media only screen and (max-width: 769px) {
  footer {
    padding: 40px 0 10px 0;
  }
}

.footer_container {
  display: block;
}

.footer_container .footer_info img {
  height: 28px;
  margin-bottom: 20px;
}

.footer_container .footer_info p {
  max-width: 40%;
  color: #b8b9ba;
}

@media only screen and (max-width: 992px) {
  .footer_container .footer_info p {
    max-width: 60%;
  }
}

@media only screen and (max-width: 769px) {
  .footer_container .footer_info p {
    max-width: none;
  }
}

.footer_container .footer_info a {
  margin-right: 15px;
}

.footer_container .footer_info a i {
  color: white;
  font-size: 22px;
}

@media only screen and (max-width: 769px) {
  .footer_container .footer_info {
    text-align: center;
  }
}

.footer_container .footer_copyright {
  margin-top: 50px;
}

.footer_container .footer_copyright .designed_col {
  text-align: right;
}

.footer_container .footer_copyright .designed_col a {
  text-decoration: none;
}

.footer_container .footer_copyright p, .footer_container .footer_copyright a {
  color: #b8b9ba;
}

@media only screen and (max-width: 769px) {
  .footer_container .footer_copyright p {
    text-align: center;
  }
}

.main_banner_container img {
  display: block;
  width: 100%;
}

.index_main_banner {
  min-height: 800px;
  margin-top: 75px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  background: transparent;
  position: relative;
}

@media only screen and (max-width: 1601px) {
  .index_main_banner {
    min-height: 600px;
  }
}

@media only screen and (max-width: 1400px) {
  .index_main_banner {
    min-height: 500px;
  }
}

.index_main_banner_inner {
  max-width: 1400px;
  margin-left: 12%;
}

@media only screen and (min-width: 1700px) {
  .index_main_banner_inner {
    margin-left: 20%;
  }
}

@media only screen and (min-width: 2100px) {
  .index_main_banner_inner {
    margin-left: 23%;
  }
}

@media only screen and (min-width: 2400px) {
  .index_main_banner_inner {
    margin-left: 26%;
  }
}

.index_main_banner_inner_text {
  margin-bottom: 40px;
}

.index_main_banner_inner_text .main_banner_block_title h1, .index_main_banner_inner_text .main_banner_block_title h2, .index_main_banner_inner_text .main_banner_block_title h3, .index_main_banner_inner_text .main_banner_block_title h4, .index_main_banner_inner_text .main_banner_block_title h5, .index_main_banner_inner_text .main_banner_block_title h6, .index_main_banner_inner_text .main_banner_block_title p {
  max-width: 65%;
  font-size: 2em;
  line-height: 1.2em;
}

@media only screen and (max-width: 992px) {
  .index_main_banner_inner_text .main_banner_block_title h1, .index_main_banner_inner_text .main_banner_block_title h2, .index_main_banner_inner_text .main_banner_block_title h3, .index_main_banner_inner_text .main_banner_block_title h4, .index_main_banner_inner_text .main_banner_block_title h5, .index_main_banner_inner_text .main_banner_block_title h6, .index_main_banner_inner_text .main_banner_block_title p {
    max-width: 70%;
  }
}

@media only screen and (max-width: 480px) {
  .index_main_banner_inner_text .main_banner_block_title h1, .index_main_banner_inner_text .main_banner_block_title h2, .index_main_banner_inner_text .main_banner_block_title h3, .index_main_banner_inner_text .main_banner_block_title h4, .index_main_banner_inner_text .main_banner_block_title h5, .index_main_banner_inner_text .main_banner_block_title h6, .index_main_banner_inner_text .main_banner_block_title p {
    max-width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .index_main_banner_inner_text .main_banner_block_title h1, .index_main_banner_inner_text .main_banner_block_title h2, .index_main_banner_inner_text .main_banner_block_title h3, .index_main_banner_inner_text .main_banner_block_title h4, .index_main_banner_inner_text .main_banner_block_title h5, .index_main_banner_inner_text .main_banner_block_title h6, .index_main_banner_inner_text .main_banner_block_title p {
    font-size: 22px;
  }
}

.index_main_banner_inner_text .main_banner_block_text p {
  font-size: 18px;
}

.index_main_banner_inner_text .main_banner_block_text p:first-child {
  margin-bottom: 80px;
}

@media only screen and (max-width: 1200px) {
  .index_main_banner_inner_text {
    padding-top: 30px;
  }
}

.index_main_banner_inner_text h1, .index_main_banner_inner_text h2, .index_main_banner_inner_text h3, .index_main_banner_inner_text h4, .index_main_banner_inner_text h5, .index_main_banner_inner_text h6, .index_main_banner_inner_text p, .index_main_banner_inner_text li {
  color: white;
}

.index_main_banner_inner_text li {
  margin: 10px 0;
}

.secondary_banner {
  background-color: #f04c23;
}

.secondary_banner .laptop_block {
  padding-left: 10% !important;
}

@media only screen and (min-width: 769px) {
  .secondary_banner .laptop_block {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 769px) {
  .secondary_banner .laptop_block {
    padding: 25px !important;
  }
}

.secondary_banner .laptop_block_title {
  color: white;
  margin-top: 40px;
  margin-bottom: 40px;
}

@media only screen and (max-width: 769px) {
  .secondary_banner .laptop_block_title {
    margin-top: 0;
  }
}

.secondary_banner .laptop_block_title p {
  line-height: 1.2em;
  font-size: 2em;
}

@media only screen and (max-width: 480px) {
  .secondary_banner .laptop_block_title p {
    font-size: 22px;
  }
}

.secondary_banner .laptop_block_text_container {
  color: white;
}

.secondary_banner .laptop_block_text_container .laptop_block_text {
  opacity: 0;
  margin-bottom: 40px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}

.secondary_banner .laptop_block_text_container .laptop_block_text img {
  height: 30px;
  margin-right: 20px;
}

@media only screen and (max-width: 1260px) {
  .secondary_banner .laptop_block_text_container .laptop_block_text {
    margin-bottom: 15px;
  }
}

.secondary_banner .laptop_block_text_container .laptop_block_text span:first-child {
  margin-right: 20px;
}

@media only screen and (max-width: 769px) {
  .secondary_banner .laptop_block {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
}

@media only screen and (max-width: 1920px) {
  .secondary_banner .laptom_image_col {
    position: relative;
  }
  .secondary_banner .laptom_image_col img {
    position: absolute;
    right: 0;
    top: 0;
    height: 640px;
  }
}

@media only screen and (max-width: 1260px) {
  .secondary_banner .laptom_image_col img {
    height: 600px;
  }
}

@media only screen and (max-width: 769px) {
  .secondary_banner .laptom_image_col {
    display: none;
  }
}

@media only screen and (min-width: 1921px) {
  .secondary_banner .laptom_image_col .cropped {
    display: none;
  }
}

@media only screen and (max-width: 1920px) {
  .secondary_banner .laptom_image_col .original {
    display: none;
  }
}

.secondary_banner:before {
  content: '\25bc';
  padding-left: 50%;
  color: white;
  font-size: 60px;
}

@media only screen and (max-width: 769px) {
  .secondary_banner:before {
    display: none;
  }
}

.big_text, .index_title_section.section_get_paid .title h1, .index_title_section.section_get_paid .title h2, .index_title_section.section_get_paid .title h3, .index_title_section.section_get_paid .title h4, .index_title_section.section_get_paid .title h5, .index_title_section.section_get_paid .title h6, .index_title_section.section_get_paid .title h7, .index_title_section.section_get_paid .title p, .index_title_section.section_solve_prob .title h1, .index_title_section.section_solve_prob .title h2, .index_title_section.section_solve_prob .title h3, .index_title_section.section_solve_prob .title h4, .index_title_section.section_solve_prob .title h5, .index_title_section.section_solve_prob .title h6, .index_title_section.section_solve_prob .title h7, .index_title_section.section_solve_prob .title p {
  font-size: 30px;
  color: #707579;
  padding: 0 25%;
  line-height: 34px;
}

@media only screen and (max-width: 1601px) {
  .big_text, .index_title_section.section_get_paid .title h1, .index_title_section.section_get_paid .title h2, .index_title_section.section_get_paid .title h3, .index_title_section.section_get_paid .title h4, .index_title_section.section_get_paid .title h5, .index_title_section.section_get_paid .title h6, .index_title_section.section_get_paid .title h7, .index_title_section.section_get_paid .title p, .index_title_section.section_solve_prob .title h1, .index_title_section.section_solve_prob .title h2, .index_title_section.section_solve_prob .title h3, .index_title_section.section_solve_prob .title h4, .index_title_section.section_solve_prob .title h5, .index_title_section.section_solve_prob .title h6, .index_title_section.section_solve_prob .title h7, .index_title_section.section_solve_prob .title p {
    padding: 0 20%;
  }
}

@media only screen and (max-width: 1200px) {
  .big_text, .index_title_section.section_get_paid .title h1, .index_title_section.section_get_paid .title h2, .index_title_section.section_get_paid .title h3, .index_title_section.section_get_paid .title h4, .index_title_section.section_get_paid .title h5, .index_title_section.section_get_paid .title h6, .index_title_section.section_get_paid .title h7, .index_title_section.section_get_paid .title p, .index_title_section.section_solve_prob .title h1, .index_title_section.section_solve_prob .title h2, .index_title_section.section_solve_prob .title h3, .index_title_section.section_solve_prob .title h4, .index_title_section.section_solve_prob .title h5, .index_title_section.section_solve_prob .title h6, .index_title_section.section_solve_prob .title h7, .index_title_section.section_solve_prob .title p {
    padding: 0 10%;
  }
}

@media only screen and (max-width: 769px) {
  .big_text, .index_title_section.section_get_paid .title h1, .index_title_section.section_get_paid .title h2, .index_title_section.section_get_paid .title h3, .index_title_section.section_get_paid .title h4, .index_title_section.section_get_paid .title h5, .index_title_section.section_get_paid .title h6, .index_title_section.section_get_paid .title h7, .index_title_section.section_get_paid .title p, .index_title_section.section_solve_prob .title h1, .index_title_section.section_solve_prob .title h2, .index_title_section.section_solve_prob .title h3, .index_title_section.section_solve_prob .title h4, .index_title_section.section_solve_prob .title h5, .index_title_section.section_solve_prob .title h6, .index_title_section.section_solve_prob .title h7, .index_title_section.section_solve_prob .title p {
    padding: 0 3%;
  }
}

@media only screen and (max-width: 480px) {
  .big_text, .index_title_section.section_get_paid .title h1, .index_title_section.section_get_paid .title h2, .index_title_section.section_get_paid .title h3, .index_title_section.section_get_paid .title h4, .index_title_section.section_get_paid .title h5, .index_title_section.section_get_paid .title h6, .index_title_section.section_get_paid .title h7, .index_title_section.section_get_paid .title p, .index_title_section.section_solve_prob .title h1, .index_title_section.section_solve_prob .title h2, .index_title_section.section_solve_prob .title h3, .index_title_section.section_solve_prob .title h4, .index_title_section.section_solve_prob .title h5, .index_title_section.section_solve_prob .title h6, .index_title_section.section_solve_prob .title h7, .index_title_section.section_solve_prob .title p {
    padding: 0;
    font-size: 22px;
  }
}

.small_text, .index_title_section.section_get_paid .text, .index_title_section.section_solve_prob .text {
  font-size: 14px;
  color: #707579;
  padding: 0 20%;
}

@media only screen and (max-width: 1601px) {
  .small_text, .index_title_section.section_get_paid .text, .index_title_section.section_solve_prob .text {
    padding: 0 15%;
  }
}

@media only screen and (max-width: 1200px) {
  .small_text, .index_title_section.section_get_paid .text, .index_title_section.section_solve_prob .text {
    padding: 0 5%;
  }
}

@media only screen and (max-width: 769px) {
  .small_text, .index_title_section.section_get_paid .text, .index_title_section.section_solve_prob .text {
    padding: 0 1%;
  }
}

@media only screen and (max-width: 480px) {
  .small_text, .index_title_section.section_get_paid .text, .index_title_section.section_solve_prob .text {
    padding: 0;
  }
}

.index_title_section {
  background-color: #fafafa;
  padding: 50px 0;
}

.index_title_section p {
  text-align: center;
}

.index_title_section.section_get_paid .title h1, .index_title_section.section_get_paid .title h2, .index_title_section.section_get_paid .title h3, .index_title_section.section_get_paid .title h4, .index_title_section.section_get_paid .title h5, .index_title_section.section_get_paid .title h6, .index_title_section.section_get_paid .title h7, .index_title_section.section_get_paid .title p {
  text-align: center;
}

.index_title_section.section_get_paid .text {
  text-align: center;
}

.index_title_section.section_solve_prob .title h1, .index_title_section.section_solve_prob .title h2, .index_title_section.section_solve_prob .title h3, .index_title_section.section_solve_prob .title h4, .index_title_section.section_solve_prob .title h5, .index_title_section.section_solve_prob .title h6, .index_title_section.section_solve_prob .title h7, .index_title_section.section_solve_prob .title p {
  text-align: center;
}

.index_title_section.section_solve_prob .text {
  text-align: center;
}

.index_feature_section {
  background-color: #fafafa;
}

.index_feature_section.image_right .text_col {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.index_feature_section.image_right .img_col {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}

.index_feature_section.image_right .img_col img {
  width: 100%;
}

.index_feature_section.image_right .container {
  margin-right: 0;
  padding-right: 0;
  max-width: none;
  padding-left: 20%;
}

@media only screen and (max-width: 2600px) {
  .index_feature_section.image_right .container {
    padding-left: 626px;
  }
}

@media only screen and (max-width: 2300px) {
  .index_feature_section.image_right .container {
    padding-left: 470px;
  }
}

@media only screen and (max-width: 1950px) {
  .index_feature_section.image_right .container {
    padding-left: 320px;
  }
}

@media only screen and (max-width: 1750px) {
  .index_feature_section.image_right .container {
    padding-left: 190px;
  }
}

@media only screen and (max-width: 1650px) {
  .index_feature_section.image_right .container {
    padding-left: 160px;
  }
}

@media only screen and (max-width: 1500px) {
  .index_feature_section.image_right .container {
    padding-left: 81px;
  }
}

@media only screen and (max-width: 1400px) {
  .index_feature_section.image_right .container {
    padding-left: 60px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 992px) {
  .index_feature_section.image_right .container {
    padding-left: 30px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 769px) {
  .index_feature_section.image_right .container {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media only screen and (max-width: 480px) {
  .index_feature_section.image_right .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.index_feature_section.image_left .text_col {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  padding-left: 10%;
  padding-top: 15px;
  padding-bottom: 15px;
}

@media only screen and (max-width: 769px) {
  .index_feature_section.image_left .text_col {
    padding-left: 0;
  }
}

@media only screen and (min-width: 769px) {
  .index_feature_section.image_left .text_col .text_container {
    text-align: left;
  }
}

@media only screen and (max-width: 769px) {
  .index_feature_section.image_left .text_col .text_container p {
    width: 100%;
  }
}

@media only screen and (max-width: 769px) {
  .index_feature_section.image_left .text_col {
    order: -1;
  }
}

.index_feature_section.image_left .img_col {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

@media only screen and (max-width: 769px) {
  .index_feature_section.image_left .img_col {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-align-items: center;
    -ms-align-items: center;
    align-items: center;
  }
}

.index_feature_section.image_left .img_col img {
  width: 100%;
}

.index_feature_section.image_left .container {
  margin-left: 0;
  padding-left: 0;
  max-width: none;
  padding-right: 20%;
}

@media only screen and (max-width: 2600px) {
  .index_feature_section.image_left .container {
    padding-right: 626px;
  }
}

@media only screen and (max-width: 2300px) {
  .index_feature_section.image_left .container {
    padding-right: 470px;
  }
}

@media only screen and (max-width: 1950px) {
  .index_feature_section.image_left .container {
    padding-right: 320px;
  }
}

@media only screen and (max-width: 1750px) {
  .index_feature_section.image_left .container {
    padding-right: 190px;
  }
}

@media only screen and (max-width: 1650px) {
  .index_feature_section.image_left .container {
    padding-right: 160px;
  }
}

@media only screen and (max-width: 1500px) {
  .index_feature_section.image_left .container {
    padding-right: 81px;
  }
}

@media only screen and (max-width: 1400px) {
  .index_feature_section.image_left .container {
    padding-right: 60px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 992px) {
  .index_feature_section.image_left .container {
    padding-right: 30px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 769px) {
  .index_feature_section.image_left .container {
    padding-right: 25px;
    padding-left: 25px;
  }
}

@media only screen and (max-width: 480px) {
  .index_feature_section.image_left .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.index_feature_section .text_container .title h1, .index_feature_section .text_container .title h2, .index_feature_section .text_container .title h3, .index_feature_section .text_container .title h4, .index_feature_section .text_container .title h5, .index_feature_section .text_container .title h6, .index_feature_section .text_container .title h7, .index_feature_section .text_container .title p {
  font-size: 30px;
  color: #707579;
  line-height: 34px;
}

@media only screen and (min-width: 769px) {
  .index_feature_section .text_container .title h1, .index_feature_section .text_container .title h2, .index_feature_section .text_container .title h3, .index_feature_section .text_container .title h4, .index_feature_section .text_container .title h5, .index_feature_section .text_container .title h6, .index_feature_section .text_container .title h7, .index_feature_section .text_container .title p {
    max-width: 500px;
  }
}

@media only screen and (max-width: 769px) {
  .index_feature_section .text_container .title h1, .index_feature_section .text_container .title h2, .index_feature_section .text_container .title h3, .index_feature_section .text_container .title h4, .index_feature_section .text_container .title h5, .index_feature_section .text_container .title h6, .index_feature_section .text_container .title h7, .index_feature_section .text_container .title p {
    text-align: center;
  }
}

@media only screen and (max-width: 480px) {
  .index_feature_section .text_container .title h1, .index_feature_section .text_container .title h2, .index_feature_section .text_container .title h3, .index_feature_section .text_container .title h4, .index_feature_section .text_container .title h5, .index_feature_section .text_container .title h6, .index_feature_section .text_container .title h7, .index_feature_section .text_container .title p {
    font-size: 22px;
  }
}

.index_feature_section .text_container .text {
  font-size: 14px;
  color: #707579;
}

@media only screen and (min-width: 769px) {
  .index_feature_section .text_container .text {
    max-width: 600px;
  }
}

@media only screen and (max-width: 769px) {
  .index_feature_section .text_container .text {
    text-align: center;
  }
}

.index_feature_section .text_container hr {
  border-color: #707579;
  width: 60%;
  margin-bottom: 20px;
  margin-top: 20px;
}

@media only screen and (max-width: 769px) {
  .index_feature_section .text_container hr {
    display: none;
  }
}

.index_feature_section:last-of-type {
  padding-bottom: 50px;
}

@media only screen and (max-width: 769px) {
  .index_feature_section img {
    max-height: 250px;
    width: auto;
  }
}

.pricing_info {
  margin-top: 65px;
  background-color: #176ab4;
  padding: 30px 0 100px 0;
}

.pricing_info_icon {
  text-align: center;
}

.pricing_info_icon img {
  margin-top: 30px;
  height: 60px;
}

.pricing_info_block_title p {
  color: white;
  text-align: center;
  font-size: 2em;
  line-height: 1.2em;
}

.pricing_info_block_text p {
  padding-left: 15%;
  padding-right: 15%;
  color: #e8f0f8;
  font-size: 14px;
}

@media only screen and (max-width: 480px) {
  .pricing_info_block_text p {
    padding: 0 20px;
  }
}

.pricing_info_install_button {
  text-align: center;
  margin-bottom: 40px;
}

.cards_row > .col:first-child {
  padding: 0 5% 0 10%;
}

.cards_row > .col:last-child {
  padding: 0 10% 0 5%;
}

@media only screen and (max-width: 1281px) {
  .cards_row > .col:first-child {
    padding: 0 2.5% 0 5%;
  }
  .cards_row > .col:last-child {
    padding: 0 5% 0 2.5%;
  }
}

@media only screen and (max-width: 769px) {
  .cards_row > .col:first-child {
    padding: 0 5%;
    margin-bottom: 40px;
  }
  .cards_row > .col:last-child {
    padding: 0 5%;
  }
}

.cards_row > .col .pricing_card {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);
  background-color: #fafafa;
  padding: 20px 50px;
}

@media only screen and (max-width: 480px) {
  .cards_row > .col .pricing_card {
    padding: 20px 20px;
  }
}

.cards_row > .col .pricing_card .pricing_card_title {
  text-align: center;
  font-size: 1.3em;
  margin-bottom: 30px;
  color: #707579;
}

.cards_row > .col .pricing_card .pricing_card_text {
  color: #707579;
  font-size: 14px;
}

.cards_row > .col .pricing_card .pricing_card_text hr {
  width: 80%;
}

.cards_row > .col .pricing_card .pricing_card_money_amount {
  color: #176ab4;
  font-size: 3em;
  margin-bottom: 30px;
  text-align: center;
}

.cards_row > .col .pricing_card .pricing_card_money_amount p:before {
  content: "€";
  font-size: 24px;
  position: relative;
  bottom: 15px;
}

.cards_row > .col .pricing_card .button_container {
  margin-top: 40px;
  margin-bottom: 20px;
  text-align: center;
}

.cards_row > .col .pricing_card > p {
  font-size: 14px;
  color: #707579;
}

.pricing_card {
  border-radius: 20px;
  background-color: #fafafa;
}

.pricing_faq {
  background-color: #fafafa;
  padding-bottom: 30px;
}

.pricing_faq .faq_title {
  text-align: center;
  padding: 40px 0;
  font-size: 2em;
  line-height: 1.2em;
  color: #707579;
}

.pricing_faq #faq_collapse {
  padding-left: 15%;
  padding-right: 15%;
}

@media only screen and (max-width: 480px) {
  .pricing_faq #faq_collapse {
    padding: 0 20px;
  }
}

.pricing_faq #faq_collapse .collapse-toggle {
  display: block;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  text-decoration: none;
}

.pricing_faq #faq_collapse .collapse-toggle .circle {
  position: absolute;
  top: -2px;
  left: 0;
}

.pricing_faq #faq_collapse .collapse-toggle .circle i {
  font-size: 0.5em;
  color: #176ab4;
}

.pricing_faq #faq_collapse .collapse-toggle .angle {
  position: absolute;
  top: -2px;
  right: 0;
}

.pricing_faq #faq_collapse .collapse-toggle .angle i {
  color: #176ab4;
}

.pricing_faq #faq_collapse .collapse-toggle p {
  padding: 0 15px;
  color: #707579;
}

.pricing_faq #faq_collapse .collapse-box {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  font-size: 14px;
  color: #707579;
}

.pricing_faq #faq_collapse .collapse-box .close-box-icon {
  text-align: right;
}

.pricing_faq #faq_collapse .collapse-box .close-box-icon i {
  color: red;
  cursor: pointer;
}

.customer_though_section {
  margin-top: 78px;
  background-color: #f04c23;
  padding: 50px 0 65px 0;
}

.customer_though_section .bubble {
  background-color: #fff;
  padding: 60px 70px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  border-radius: 35px;
  position: relative;
}

@media only screen and (max-width: 769px) {
  .customer_though_section .bubble {
    padding: 30px 40px;
  }
}

.customer_though_section .bubble p {
  text-align: center;
}

.customer_though_section .bubble img {
  position: absolute;
  bottom: -68px;
  right: 100px;
}

.customer_though_section .customer_name {
  margin-top: 60px;
}

.customer_though_section .customer_name p {
  text-align: right;
  color: #fff;
}

.tour_img_col {
  padding-top: 40px;
  padding-bottom: 40px;
}

.tour_img_col .cropped {
  display: block;
}

@media only screen and (max-width: 769px) {
  .tour_img_col .cropped {
    display: none;
  }
}

.tour_img_col .original {
  display: block;
}

@media only screen and (min-width: 769px) {
  .tour_img_col .original {
    display: none;
  }
}

@media only screen and (max-width: 769px) {
  .tour_img_col img {
    width: auto !important;
  }
}

.setup_container {
  background-color: #176ab4;
  margin-top: 80px;
  padding-top: 100px;
}

.setup_container .setup_icon_col {
  position: relative;
}

.setup_container .setup_icon_col img {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 350px;
}

.setup_container .text_container {
  max-width: 70%;
  min-height: 400px;
}

.setup_container .text_container * {
  color: white;
}

.setup_container .text_container .title {
  margin-bottom: 40px;
}

.setup_container .text_container .title p {
  font-size: 2em;
  line-height: 1.2em;
}

.setup_container .text_container .text {
  font-size: 14px;
}

@media only screen and (max-width: 480px) {
  .setup_container {
    padding-top: 20px;
  }
  .setup_container .setup_icon_col {
    display: none;
  }
  .setup_container #setup_text_col {
    max-width: 100%;
    width: 100%;
    flex-basis: 100%;
  }
  .setup_container .text_container {
    max-width: 100%;
    min-height: 0;
  }
}

.setup_form_container {
  background-color: #fafafa;
}

@media only screen and (max-width: 480px) {
  .setup_form_container #form_spacer {
    display: none;
  }
  .setup_form_container #form_col {
    max-width: 100%;
    width: 100%;
    flex-basis: 100%;
  }
}

.assistant_form_container {
  background-color: #fff;
  padding: 40px 120px;
  border-radius: 15px;
  margin-bottom: 50px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 1281px) {
  .assistant_form_container {
    padding: 40px 60px;
  }
}

@media only screen and (max-width: 480px) {
  .assistant_form_container {
    padding: 20px 20px;
  }
}

.assistant_form_container .form_title {
  font-size: 1.5em;
  text-align: center;
  margin-bottom: 100px;
  color: #707579;
}

@media only screen and (max-width: 1281px) {
  .assistant_form_container .form_title {
    margin-bottom: 30px;
  }
}

.assistant_form_container input {
  border: 0px;
  border-bottom: 2px solid #707579;
  font-weight: bold;
  color: #707579;
}

.assistant_form_container label {
  color: #000;
  font-weight: 700;
  font-size: 1em;
  color: #707579;
}

.assistant_form_container textarea {
  padding: 0;
  resize: none;
  background: transparent url("/assets/under.png") repeat;
  border: none;
  height: 150px;
  width: 100%;
  overflow: hidden;
  line-height: 38px;
  color: #707579;
}

.assistant_form_container .submit-btn-container {
  text-align: right;
}
