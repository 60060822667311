.customer_though_section{
	margin-top: 78px;
	background-color: $orange;
	padding: 50px 0 65px 0;
	.bubble{
		background-color: #fff;
		padding: 60px 70px;
		@include devices_max(medium){
			padding: 30px 40px;
		}
		box-shadow: 0 10px 10px rgba(0,0,0,0.2);
		border-radius: 35px;
		position: relative;
		p{
			text-align: center;
		}
		img{
			position: absolute;
			bottom: -68px;
			right: 100px;
		}
	}
	.bubble:after{
		
	}
	.bubble:before{
		
	}
	.customer_name{
		margin-top: 60px;
		p{
			text-align: right;
			color: #fff;
		}
	}
}
.tour_img_col {
	padding-top: 40px;
	padding-bottom: 40px;
	.cropped {
		display: block;
		@include devices_max(medium) {
			display: none;
		}
	}
	.original {
		display: block;
		@include devices_min(medium) {
			display: none;
		}
	}
	img{
		@include devices_max(medium) {
			width: auto!important;	
		}
	}
}