.pricing_info {
	margin-top: 65px;
	background-color: $blue;
	padding: 30px 0 100px 0;
	&_icon {
		text-align: center;
		img{
			margin-top: 30px;
			height: 60px;
		}
	}
	&_block {
		&_title {
			p {
				color: white;
				text-align: center;
				font-size: 2em;
				line-height: 1.2em;
			}
		}
		&_text {
			p {
				padding-left: 15%;
				padding-right: 15%;
				@include devices_max(small){
					padding: 0 20px;
				}
				color: #e8f0f8;
				font-size: 14px;
			}
		}
	}
	&_install_button {
		text-align: center;
		margin-bottom: 40px;
	}
}

.cards_row {
	&>.col {
		&:first-child {
			padding: 0 5% 0 10%;
		}
		&:last-child {
			padding: 0 10% 0 5%;
		}
		@include custom_devices_max(1281px){
			&:first-child {
				padding: 0 2.5% 0 5%;
			}
			&:last-child {
				padding: 0 5% 0 2.5%;
			}
		}
		@include devices_max(medium){
			&:first-child {
				padding: 0 5%;
				margin-bottom: 40px;
			}
			&:last-child {
				padding: 0 5%;
			}
		}
		.pricing_card {
			box-shadow: 5px 5px 10px rgba(0,0,0,0.4);
			background-color: $background-white;
			padding: 20px 50px;
			@include devices_max(small){
				padding: 20px 20px;
			}
			.pricing_card_title  {
				text-align: center;
				font-size: 1.3em;
				margin-bottom: 30px;
				color: $text-color;
			}
			.pricing_card_text{
				color: $text-color;
				font-size: 14px;
				hr{
					width: 80%;
				}
			}
			.pricing_card_money_amount{
				color: $blue;
				font-size: 3em;
				margin-bottom: 30px;
				text-align: center;
				p:before{
					content: "€";
					font-size: 24px;
					position: relative;
					bottom: 15px;
				}
			}
			.button_container{
				margin-top: 40px;
				margin-bottom: 20px;
				text-align: center;
			}
			&>p{
				font-size: 14px;
				color: $text-color;
			}
		}
	}
}

.pricing_card {
	border-radius: 20px;
	background-color: $background-white;
}

.pricing_faq {
	background-color: $background-white;
	padding-bottom: 30px;
	.faq_title{
		text-align: center;
		padding: 40px 0;
		font-size: 2em;
		line-height: 1.2em;
		color: $text-color;
	}
	#faq_collapse{
		padding-left: 15%;
		padding-right: 15%;
		@include devices_max(small){
			padding: 0 20px;
		}
		.collapse-toggle{
			display: block;
			position: relative;
			border-bottom: 1px solid rgba(0,0,0,0.1);
			margin-bottom: 20px;
			text-decoration: none;
			.circle{
				position: absolute;
				top: -2px;
				left: 0;
				i{
					font-size: 0.5em;
					color: $blue;
				}
			}
			.angle{
				position: absolute;
				top: -2px;
				right: 0;
				i{
					color: $blue;
				}
			}
			p{
				padding: 0 15px;
				color: $text-color;
			}
		}
		.collapse-box{
			position: relative;
			background-color: #fff;
			padding: 20px;
			border-radius: 5px;
			box-shadow: 0 5px 10px rgba(0,0,0,0.1);
			margin-bottom: 20px;
			font-size: 14px;
			color: $text-color;
			.close-box-icon{
				text-align: right;
				i{
					color: red;
					cursor: pointer;
				}
			}
		}
	}
}