footer{
    background: transparent;
    position: relative;
    padding: 100px 0 30px 0;
    @include devices_max(medium){
        padding: 40px 0 10px 0;
    }
}

.footer_container{
    display: block;
    .footer_info{
        img{
            height: 28px;
            margin-bottom: 20px;
        }
        p{
            max-width: 40%;
            @include devices_max(large){
                max-width: 60%;
            }
            @include devices_max(medium){
                max-width: none;
            }
            color: $text-footer-color;
        }
        a{
            margin-right: 15px;
            i{
                color: white;
                font-size: 22px;
            }
        }
        @include devices_max(medium){
            text-align: center;
        }
    }
    .footer_copyright{
        margin-top: 50px;
        .designed_col{
            text-align: right;
            a{
                text-decoration: none;
            }
        }
        p,a{
            color: $text-footer-color;
        }
        @include devices_max(medium){
            p{
                text-align: center;
            }
        }
    }
}


