.main_banner_container{
    img{
        display: block;
        width: 100%;
    }
}
.index_main_banner{
    min-height: 800px;
    margin-top: 75px;
    @include flex;
    @include flex-middle;
    @include devices_max(xxxlarge){
        min-height: 600px;
    }
    @include devices_max(xxlarge){
        min-height: 500px;
    }
    background: transparent;
    position: relative;
    &_inner{
        max-width: 1400px;
        margin-left: 12%;
        @include custom_devices_min(1700px){
            margin-left: 20%;
        }
        @include custom_devices_min(2100px){
            margin-left: 23%;
        }
        @include custom_devices_min(2400px){
            margin-left: 26%;
        }
        &_text{
            margin-bottom: 40px;
            .main_banner_block_title{
                h1,h2,h3,h4,h5,h6,p{
                    max-width: 65%;
                    font-size: 2em;
                    line-height: 1.2em;
                    @include devices_max(large){
                        max-width: 70%;
                    }
                    @include devices_max(small){
                        max-width: 100%;
                    }
                    @include devices_max(small){
                        font-size: 22px;
                    }
                }
            }
            .main_banner_block_text{
                p{
                    font-size: 18px;
                    &:first-child{
                        margin-bottom: 80px;
                    }
                }
            }
            @include devices_max(xlarge){
                padding-top: 30px;
            }
            h1,h2,h3,h4,h5,h6,p,li{
                color: white;
            }
            li{
                margin: 10px 0;
            }
        }
    }
}

.secondary_banner{
    background-color: $orange;
    .laptop_block{
        padding-left: 10%!important;
        
        @include devices_min(medium){
            padding-top: 40px;
            padding-bottom: 40px;
        }
        @include devices_max(medium){
            padding: 25px!important;
        }
        &_title{
            color: white;
            margin-top: 40px;
            margin-bottom: 40px;
            @include devices_max(medium){
                margin-top: 0;
            }
            p{
                line-height: 1.2em;
                font-size: 2em;
                @include devices_max(small){
                    font-size: 22px;
                }
            }
        }
        &_text_container{
            color: white;
            .laptop_block_text{
                img{
                    height: 30px;
                    margin-right: 20px;
                }
                opacity: 0;
                margin-bottom: 40px;
                @include custom_devices_max(1260px){
                    margin-bottom: 15px;
                }
                @include flex;
                @include flex-middle;
                span{
                    // icon
                    &:first-child{
                        margin-right: 20px;
                    }
                    // text
                    &:last-child{
                        @extend .foco_light;
                    }
                }
            }
        }
        @include devices_max(medium){
            width: 100%;
            max-width: 100%;
            flex-basis: 100%;
        }
    }
    .laptom_image_col{
        @include custom_devices_max(1920px){
            position: relative;
            img{
                position: absolute;
                right: 0;
                top: 0;
                height: 640px;
            }
        }
        @include custom_devices_max(1680px){
            img{
                
            }
        }
        @include custom_devices_max(1600px){
            img{
                
            }
        }
        @include custom_devices_max(1440px){
            img{
                
            }
        }
        @include custom_devices_max(1366px){
            img{
                
            }
        }
        @include custom_devices_max(1260px){
            img{
                height: 600px;
            }
        }
        @include devices_max(medium){
            display: none;
        }
        .cropped {
            @include custom_devices_min(1921px){ 
                display: none;
            }
        }
        .original {
            @include custom_devices_max(1920px){ 
                display: none;
            }
        }
    }
    &:before {
        content: '\25bc';
        padding-left: 50%;
        color: white;
        font-size: 60px;
        @include devices_max(medium) {
            display: none;
        }
    }
}

.big_text{
    font-size: 30px;
    color: $text-color;
    padding: 0 25%;
    line-height: 34px;
    @include devices_max(xxxlarge){
        padding: 0 20%;
    }
    @include devices_max(xlarge){
        padding: 0 10%;
    }
    @include devices_max(medium){
        padding: 0 3%;
    }
    @include devices_max(small){
        padding: 0;
        font-size: 22px;
    }
}

.small_text{
    font-size: 14px;
    color: $text-color;
    padding: 0 20%;
    @include devices_max(xxxlarge){
        padding: 0 15%;
    }
    @include devices_max(xlarge){
        padding: 0 5%;
    }
    @include devices_max(medium){
        padding: 0 1%;
    }
    @include devices_max(small){
        padding: 0;
    }
}

.index_title_section{
    background-color: $background-white;
    padding: 50px 0;
    p{
        text-align: center;
    }
    &.section_get_paid{
        .title{
            h1,h2,h3,h4,h5,h6,h7,p{
                @extend .big_text;
                text-align: center;
            }
        }
        .text{
                @extend .small_text;
                text-align: center;
        }
    }
    &.section_solve_prob{
        .title{
            h1,h2,h3,h4,h5,h6,h7,p{
                @extend .big_text;
                text-align: center;
            }
        }
        .text{
            
                @extend .small_text;
                text-align: center;
            
        }
    }
}

.index_feature_section{
    background-color: $background-white;
    &.image_right{
        .text_col{
            @include flex;
            @include flex-middle;
            padding-top: 15px;
            padding-bottom: 15px;
        }
        .img_col{
            @include flex;
            @include flex-right;
            @include flex-middle;
            img{
                width: 100%;
            }
        }
        .container{
            margin-right: 0;
            padding-right: 0;
            max-width: none;
            padding-left: 20%;
            @include custom_devices_max(2600px){
                padding-left: 626px;
            }
            @include custom_devices_max(2300px){
                padding-left: 470px;
            }
            @include custom_devices_max(1950px){
                padding-left: 320px;
            }
            @include custom_devices_max(1750px){
                padding-left: 190px;
            }
            @include custom_devices_max(1650px){
                padding-left: 160px;
            }
            @include custom_devices_max(1500px){
                padding-left: 81px;
            }
            @include devices_max(xxlarge){
                padding-left: 60px;
            }
            @include devices(medium,large){
                padding-left: 30px;
            }
            @include devices(small,medium){
                padding-left: 25px;
                padding-right: 25px;
            }
            @include devices_max(small){
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
    &.image_left{
        .text_col{
            @include flex;
            @include flex-center;
            @include flex-middle;
            padding-left: 10%;
            @include devices_max(medium){
                padding-left: 0;
            }
            padding-top: 15px;
            padding-bottom: 15px;
            .text_container{

                @include devices_min(medium){
                    text-align: left;
                }
                p{
                    @include devices_max(medium){
                        width: 100%;
                    }
                }
            }
            @include devices_max(medium){
                order: -1;
            }
        }
        .img_col{
            @include flex;
            @include flex-middle;
            @include flex-center; 
            @include devices_max(medium){
                @include flex;
                @include flex-right;
                @include flex-middle;
            }
            img{
                width: 100%;
            }
        }
        .container{
            margin-left: 0;
            padding-left: 0;
            max-width: none;
            padding-right: 20%;
            @include custom_devices_max(2600px){
                padding-right: 626px;
            }
            @include custom_devices_max(2300px){
                padding-right: 470px;
            }
            @include custom_devices_max(1950px){
                padding-right: 320px;
            }
            @include custom_devices_max(1750px){
                padding-right: 190px;
            }
            @include custom_devices_max(1650px){
                padding-right: 160px;
            }
            @include custom_devices_max(1500px){
                padding-right: 81px;
            }
            @include devices_max(xxlarge){
                padding-right: 60px;
            }
            @include devices(medium,large){
                padding-right: 30px;
            }
            @include devices(small,medium){
                padding-right: 25px;
                padding-left: 25px;
            }
            @include devices_max(small){
                padding-right: 15px;
                padding-left: 15px;
            }
        }
        .text_container{
            
        }
    }
    .text_container{
        .title{
            h1,h2,h3,h4,h5,h6,h7,p{
                font-size: 30px;
                color: $text-color;
                line-height: 34px;
                @include devices_min(medium){
                    max-width: 500px;
                }
                @include devices_max(medium){
                    text-align: center;
                }
                @include devices_max(small){
                    font-size: 22px;
                }
            }
        }
        .text{ 
            font-size: 14px;
            color: $text-color;
            @include devices_min(medium){
                max-width: 600px;
            }
            @include devices_max(medium){
                text-align: center;
            }
        }
        hr{
            border-color: $text-color;
            width: 60%;
            margin-bottom: 20px;
            margin-top: 20px;
            @include devices_max(medium){
                display: none;
            }
        }
    }
    &:last-of-type{
        padding-bottom: 50px;
    }
    img{
        @include devices_max(medium){
            max-height: 250px;
            width: auto;
        }
    }
}
