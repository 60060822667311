/*------------------------------------*\

  #BUTTONS
\*------------------------------------*/

.login_button{
    color: white;
    background-color: $orange;
    border-radius: 20px;
    min-height: 36px;
    padding: 9px 24px;
    transition: all 0.5s ease;
    &:hover{
        color: white;
        border-color: #d2421f;
    }
}

.watch_video_button{
    color: white;
    background: transparent;
    border-radius: 20px;
    min-height: 36px;
    border: 1px solid white;
    margin-left: 15px;
    padding: 9px 24px;
    transition: all 0.5s ease;
    @include custom_devices_max(371px){
        margin: 15px 0 15px 0;
    }
    &:hover{
        color: white;
    }
}
