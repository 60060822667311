header{
    padding: 20px 0;
    position: fixed;
    z-index: $header-z-index;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
    @include devices_max(large){
        padding: 7px 0;
    }
    background-color: $background-white;
    .logo_col{
        @include devices_min(medium){
            @include flex;
            @include flex-middle;
        }
        @include devices_max(medium){
            img{
                margin-top: 6px;
            }
        }
        .logo_container{
            position: relative;
            span{
                position: absolute;
                bottom: -12px;
                right: 0;
                font-size: 0.6875em;
                @include devices_max(large){
                    display: none;
                }
            }
            img{
                height: 28px;
                @include devices_max(medium){
                    margin-top: 17px;
                }
            }
        }
        @include devices_max(large){
            max-width: 83.33333% !important;
            width: 83.33333% !important;
            flex-basis: 83.33333% !important;
        }
    }
    .menu_col{
        @include flex;
        @include flex-right;
        @include flex-middle;
        #main_menu_ul{
            margin: 0;
            li{
                list-style-type: none;
                display: inline-block;
                a{
                    text-decoration: none;
                    color: $text-bold-color;
                    padding: 0 10px;
                    font-size: 0.875em;
                    font-weight: 400;
                    transition: color 0.3s ease;
                    &:hover{
                        color: $orange;
                    }
                }
                &.active{
                    a{
                        color: $orange;
                    }
                }
            }
            @include devices_max(large){
                display: none;
            }
        }
        .login_button{
            @include devices_max(large){
                display: none;
            }
        }
        @include devices_min(large){
            #burger_ic{
                display: none;
            }
        }
        @include devices_max(large){
            max-width: 16.66667% !important;
            width: 16.66667% !important;
            flex-basis: 16.66667% !important;
        }
        .flag_icon{
            margin-left: 10px;
            @include devices_max(large){
                display: none;
            }
        }
    }
}

.burger_menu_container{
    max-height: 0;
    transition: max-height 1s ease;
    overflow: hidden;
    #burger_menu_ul{
        margin: 0;
        li{
            a{
                display: block;
                text-align: center;
                padding: 10px 0;
                color: $text-color;
                text-decoration: none;     
                font-weight: 400;
            }
            &.active{
                a{
                    color: $orange;
                }
            }
        }
    }
    .flag_burger{
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: center;
    }
}
